var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"footer-tag":"footer"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-button-loading',{attrs:{"text":"ACTUALIZAR","type":"submit","processing":_vm.processing}})]},proxy:true}])},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"names"}},[_c('strong',[_vm._v("NOMBRE(S)")])]),_c('validation-provider',{attrs:{"rules":"required","name":"nombre(s)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"names","placeholder":"Ingrese el nombre ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.names),callback:function ($$v) {_vm.$set(_vm.formData, "names", $$v)},expression:"formData.names"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('label',{attrs:{"for":"surnames"}},[_c('strong',[_vm._v("APELLIDO(S)")])]),_c('validation-provider',{attrs:{"rules":"required","name":"apellido(s)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"surnames","placeholder":"Ingrese el apellido ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.surnames),callback:function ($$v) {_vm.$set(_vm.formData, "surnames", $$v)},expression:"formData.surnames"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('label',{attrs:{"for":"email"}},[_c('strong',[_vm._v("CORREO ELECTRÓNICO ("),_c('span',{staticClass:"text-primary"},[_vm._v("PRINCIPAL")]),_vm._v(")")])]),_c('validation-provider',{attrs:{"rules":"required|email","name":"correo electrónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","autocomplete":"login-email","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" "),_c('br')]):_vm._e()]}}])}),_c('small',{staticClass:"text-primary"},[_vm._v("Este correo será utilizado para iniciar sesión, notificaciones principales y notificaciones secundarias.")])],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"correo secundario","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_c('strong',[_vm._v("CORREO ELECTRÓNICO ("),_c('span',{staticClass:"text-primary"},[_vm._v("SECUNDARIO")]),_vm._v(")")])]),_c('b-form-input',{attrs:{"id":"secondary_email","name":"secondary_email","state":errors.length > 0 ? false:null,"placeholder":"Ingrese un correo secundario"},model:{value:(_vm.formData.secondary_email),callback:function ($$v) {_vm.$set(_vm.formData, "secondary_email", $$v)},expression:"formData.secondary_email"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" "),_c('br')]):_vm._e()]}}])}),_c('small',{staticClass:"text-primary"},[_vm._v("Este correo será utilizado adicionalmente para notificaciones secundarias (Actualización de credenciales, Código de seguridad al iniciar sesión y Enlace para recuperar contraseña).")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }